<template>
  <div class="title">
    <div class="title-c">
      {{title}}
    </div>
    <div class="title-r">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'budget-title',
  props: {
    title: { type: String }
  }
}
</script>

<style scoped lang="less">
.title {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.title-c {
  line-height: 1;
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #000000;
}
.title-r {
  flex: 1;
  height: 100%;
}
</style>