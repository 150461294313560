<template>
  <div class="block" :class="{'orange': theme === 'orange'}">
    <div class="title">
      <div class="sx"></div>
      <div class="title-c">{{title}}</div>
      <div class="actions">
        <div class="action" @click="$emit('clickBtn', item.key)" v-for="item in actions" :key="item.key">
          {{item.label}}
        </div>
      </div>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
    actions: { type: Array , default: () => {
      return []
    }},
    theme: {
      type: String
    }
  }
}
</script>

<style scoped lang="less">
.block {
  background: #fff;
  position: relative;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  .title {
    display: flex;
    align-items: center;
    height: 42px;
    .sx {
      width: 5px;
      height: 16px;
      background: #0172FF;
      border-radius: 3px;
      margin-left: 12px;
      margin-right: 7px;
    }
  }
  .title-c {
    font-size: 16px;
    line-height: 1;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
  }
  .content {
    width: 100%;
    height: calc(100% - 42px);
  }
}
.actions {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .action {
    margin-right: 15px;
    background: rgba(55,118,237,13%);
    border-radius: 17px;
    padding: 0 14px;
    height: 26px;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 26px;
    color: #3776ED;
  }
}
.orange {
  .sx {
    background: #FF9D6B !important;
  }
}
</style>