<template>
  <div class="money">
    <span class="dw">¥</span>
    {{showText}}
  </div>
</template>

<script>
export default {
  props: {
    money: { type: Number }
  },
  computed: {
    showText () {
      if (isNaN(this.money) || this.money === null || this.money === '') {
        return ''
      }
      let m = this.money.toFixed(0)
      let str = ''
      let i = m.length - 1
      let j = 0
      while (i > -1) {
        if (j > 0 && j % 3 === 0) {
          str = ',' + str
        }
        str = m[i] + str
        j += 1
        i -= 1
      }
      return str
    }
  }
}
</script>

<style scoped lang="less">
.money {
  font-size: 25px;
  font-family: DIN-Bold, DIN;
  font-weight: bold;
  color: #222222;
  line-height: 30px;
  .dw {
    font-size: 20px;
    line-height: 30px;
  }
}
</style>
