<style scoped></style>

<template>
  <div></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: {
    dataList: { type: Array },
    colors: { type: Array },
    legendBottom: { type: String }
  },
  data () {
    return {
      chart: null,
    }
  },
  mounted () {
    this.render()
  },
  watch: {
    dataList: {
      deep: true,
      handler () {
        this.render()
      }
    }
  },
  methods: {
    render () {
      if (!this.chart) {
        this.chart = echarts.init(this.$el)
      }
      this.chart.setOption(this.getOptions(), {
        notMerge: true
      })
    },
    getOptions () {
      return {
        grid: { left: '3%', right: '10%', bottom: '0%', top: '10%'},
        color: this.colors,
        tooltip: {
          trigger: 'item',
          formatter: function (param) {
            return param.name + ' ' + param.percent + '%'
          }
        },
        legend: {
          orient: 'vertical',
          right: '20',
          bottom: this.legendBottom || '30%',
          itemWidth: 10,
          itemHeight: 10
        },
        series: [
          {
            type: 'pie',
            center: ['35%', '50%'],
            radius: ['35%', '70%'],
            label: { show: false },
            labelLine: { show: false },
            data: this.dataList
          }
        ]
      }
    },
  }
}
</script>