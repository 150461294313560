<template>
  <div class="page" v-loading="loading">
    <Title :title="title || (yearNum + '年采购方式')">
      <div class="b-d-t-s">
        <fm-date-picker @change="yearChange" style="width: 7rem;" v-model="year" type="year" placeholder="请选择年份"></fm-date-picker>
      </div>
    </Title>
    <div class="top" v-if="info">
      <div class="t-1">
        <Total :data="showDetail"
          :actions="[{key: 'detail', label: '明细'}]"
          @clickBtn="goDetailList"></Total>
      </div>
      <div class="t-2">
        <BusOrg
          @chooseData="updateSearchParm"
          :data="detail || []"></BusOrg>
      </div>
      <div class="t-3">
        <Info :data="info"></Info>
      </div>
    </div>
    <div class="center" v-if="info">
      <SubmitOrg
        @chooseData="updateSearchParm"
        theme="orange" :data="detail || []"></SubmitOrg>
    </div>
    <div class="bottom" v-if="info">
      <div class="b-1">
        <FileBlock :fileIds="info.fileIds"></FileBlock>
      </div>
      <div class="b-2">
        <PassPie :data="passData"></PassPie>
      </div>
      <div class="b-3">
        <UsePie :data="showDetail"></UsePie>
      </div>
    </div>
    <div class="center" v-if="!info">
      <div class="no-img">
        <img src="/static/images/budget/wait.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { purchaseMethodBatchRequest, purchaseMethodBatchDetailsRequest } from '../../api'

import {
  getBatchInfo,
  detailFilter
} from './lib'

import Title from './components/cmp/title'
import Total from './components/batch_detail/total'
import Info from './components/batch_detail/info'
import BusOrg from './components/batch_detail/busOrg'
import UsePie from './components/batch_detail/usePie'
import SubmitOrg from './components/batch_detail/submitOrg'
import FileBlock from './components/batch_detail/files'
import PassPie from './components/batch_detail/passPie'

export default {
  components: {
    Title,
    Total,
    Info,
    BusOrg,
    UsePie,
    SubmitOrg,
    FileBlock,
    PassPie
  },
  data () {
    return {
      id: null,
      yearData: [],
      title: null,
      year: new Date(),
      info: null,
      detail: [],
      loading: false,
      searchParm: null
    }
  },
  activated () {
    if (this.$route.query.id || this.$route.query.year) {
      if (this.$route.query.id) {
        this.id = this.$route.query.id
        this.year = null
        this.title = this.$route.query.title
      }
      if (this.$route.query.year) {
        this.year = new Date(this.$route.query.year + '-02-01')
      }
    } else {
      this.year = new Date()
      this.id = null
      this.title = null
    }
    this.loadData()
  },
  computed: {
    typeList () {
      return this.$store.getters.budgetYearTypeList
    },
    yearNum () {
      return this.year ? this.year.getFullYear() : null
    },
    showDetail () {
      return this.detail.filter(v => detailFilter(v, this.searchParm))
    },
    passData () {
      let pass = this.showDetail.filter(v => v.status === 'end').reduce((c, v) => {
        return c + v.total
      }, 0)
      let notPass = this.showDetail.filter(v => v.status !== 'end').reduce((c, v) => {
        return c + v.total
      }, 0)
      return {pass, notPass}
    },
    useTotal () {
      return {
        unusedTotal: 0,
        usedTotal: 0,
        usingTotal: 0
      }
    },
  },
  methods: {
    updateSearchParm (parm) {
      if (parm && this.searchParm && parm.key === this.searchParm.key) {
        this.searchParm = null
      } else {
        this.searchParm = parm
      }
    },
    yearChange () {
      if (this.year) {
        this.id = null
        this.title = null
        this.loadData()
      }
    },
    goDetailList () {
      let query = {}
      if (this.id) {
        query.id = this.id
        query.title = this.yearData.length > 0 ? (this.yearData[0].year + ' ' + (this.yearData[0].batch || '')) : ''
      }
      if (this.yearNum) {
        query.year = this.yearNum
      }
      this.$router.push({
        name: 'internalcontrol.purchase_method.detail_list',
        query
      })
    },
    async loadData () {
      this.info = null
      this.detail = []
      let parm = {}
      let parm2 = {}
      if (this.yearNum) {
        parm.year = this.yearNum
        parm2.year = this.yearNum
      }
      if (this.id) {
        parm.id = this.id
        parm2.budgetYearId = this.id
      }
      this.loading = true
      let data = []
      if (this.$authFunsProxy.get) {
        data = await purchaseMethodBatchRequest.getInfo(parm)
      } else if (this.$authFunsProxy.getByAuth) {
        data = await purchaseMethodBatchRequest.getMyInfo(parm)
      }
      this.yearData = data
      this.info = getBatchInfo(data)
      if (this.info) {
        this.detail = []
        if (this.$authFunsProxy.get) {
          this.detail = await purchaseMethodBatchDetailsRequest.getInfo(parm2)
        } else if (this.$authFunsProxy.getByAuth) {
          this.detail = await purchaseMethodBatchDetailsRequest.getInfoByAuth(parm2)
        }
      }
      this.loading = false
    },
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #EEF3FA;
  padding: 0 16px 12px;
  height: 100%;
  .top {
    flex: 295;
    width: 100%;
    margin-top: 14px;
    display: flex;
    margin-bottom: 13px;
    .t-1 {
      height: 100%;
      flex: 386;
      margin-right: 12px;
    }
    .t-2 {
      flex: 821;
      margin-right: 12px;
      height: 100%;
    }
    .t-3 {
      flex: 492;
      height: 100%;
    }
  }
  .center {
    flex: 315;
    width: 100%;
    display: flex;
    margin-bottom: 13px;
  }
  .bottom {
    width: 100%;
    flex: 328;
    display: flex;
    .b-1 {
      flex: 824;
      margin-right: 12px;
    }
    .b-2 {
      flex: 439;
      margin-right: 12px;
    }
    .b-3 {
      flex: 439;
    }
  }
  .b-d-t-s {
    display: flex;
    height: 100%;
    margin-left: 20px;
    align-items: flex-end;
    input {
      background: rgba(0,0,0,0) !important;
    }
  }
  .no-img {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 100px;
    img {
      width: 243px;
    }
  }
}
</style>

<style lang="less">
.page {
  .b-d-t-s {
    input {
      background: rgba(0,0,0,0) !important;
    }
  }
}
</style>