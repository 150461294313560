<template>
  <div class="info">
    <div class="end-img" v-if="data.isEnd">
      <img src="/static/images/budget/end.png" alt="">
    </div>
    <div class="info-item" v-for="(item, index) in infos" :key="index">
      {{item.label}}：{{item.value}}
    </div>
  </div>
</template>

<script>
const dealDate = (date) => {
  if (date) {
    date = date.slice(0, 10)
    return date
  } else {
    return ''
  }
}

export default {
  props: {
    theme: {
      type: String
    },
    data: {
      type: Object
    }
  },
  computed: {
    infos () {
      return [{
        label: '采购申请开始时间',
        value: dealDate(this.data.startTime)
      },
      {
        label: '采购申请结束时间',
        value: dealDate(this.data.endTime)
      },
      {
        label: '参与采购申请部门',
        value: this.data.cyOrgNum + '个'
      },
      {
        label: '采购申请牵头部门',
        value: '财务科'
      },
      {
        label: '参与采购申请领导',
        value: this.data.cyLeaderNum + '个'
      }]
    }
  }
}
</script>

<style scoped lang="less">
.info {
  border-radius: 6px;
  background: linear-gradient(to right, #fd6cb0, #fd6365);
  width: 100%;
  color: #fff;
  line-height: 1;
  font-size: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
  position: relative;
}
.end-img {
  position: absolute;
  right: 47px;
  bottom: 3px;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  img {
    width: 172px;
  }
}
</style>
