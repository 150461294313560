import { render, staticRenderFns } from "./money.vue?vue&type=template&id=f93344d2&scoped=true&"
import script from "./money.vue?vue&type=script&lang=js&"
export * from "./money.vue?vue&type=script&lang=js&"
import style0 from "./money.vue?vue&type=style&index=0&id=f93344d2&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f93344d2",
  null
  
)

export default component.exports