<style scoped></style>

<template>
  <div></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: {
    dataList: { type: Array },
    colors: { type: Array },
    types: { type: Array }
  },
  data () {
    return {
      chart: null,
    }
  },
  mounted () {
    this.render()
  },
  watch: {
    dataList: {
      deep: true,
      handler () {
        this.render()
      }
    }
  },
  methods: {
    render () {
      if (!this.chart) {
        this.chart = echarts.init(this.$el)
        this.chart.on('click', 'series', this.onClick)
      }
      this.chart.setOption(this.getOptions(), {
        notMerge: true
      })
    },
    onClick (parm) {
      this.$emit('clickBar', parm)
    },
    getSeries () {
      let series = []
      let i = 0
      while (i < this.types.length) {
        let type = this.types[i]
        series.push({
          name: type,
          type: 'bar',
          stack: 'num',
          selectedMode: 'single',
          itemStyle: {
            opacity: 0.8
          },
          emphasis: {
            itemStyle: {
              opacity: 1
            },
            label: {
              fontWeight: 'bolder',
              fontSize: 12
            }
          },
          barMaxWidth: 111,
          data: this.dataList.map(v => v.data[i])
        })
        i += 1
      }
      return series
    },
    getOptions () {
      let data = {
        color: this.colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          top: 10,
          right: 10,
          itemWidth: 10,
          itemHeight: 10
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: 5,
          top: 60,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisLine: {
            lineStyle: {
              color: '#E7E7E7'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#E7E7E7'
            }
          },
          axisTick: {
            show: false
          },
          data: this.dataList.map(v => {
            return {
              value: v.name,
              textStyle: {
                color: '#000',
                fontSize: 12,
                fontWeight: 400
              }
            }
          })
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#000',
            fontSize: 12,
            fontWeight: 400
          }
        },
        series: this.getSeries(this.dataList)
      }
      return data
    }
  },
  destroyed () {
    if (this.chart) {
      this.chart.off('click', 'series', this.onClick)
    }
  }
}
</script>