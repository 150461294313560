<template>
  <Block :theme="theme" title="职能科室分类">
    <chart-bar @clickBar="clickBar" :colors="colors" :types="types" class="chart" :dataList="dataList"></chart-bar>
  </Block>
</template>

<script>
import ChartBar from '../cmp/bar'
import Block from '../cmp/block'

export default {
  components: {
    Block,
    ChartBar
  },
  props: {
    data: {
      type: Array
    },
    theme: {
      type: String
    },
    haveAppend: {
      type: Boolean,
      default: true
    },
    haveNormal: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    clickBar (parm) {
      let busOrgId = this.dataList[parm.dataIndex].id
      let key = 'busOrgId' + busOrgId
      this.$emit('chooseData', {
        __key: key,
        busOrgId
      })
    }
  },
  computed: {
    colors () {
      let allColors = this.theme === 'orange' ? ['#FF9D6B', '#FFE369'] : ['#3776ED', '#37CBED']
      return allColors
    },
    types () {
      return ['金额']
    },
    dataList () {
      let orgMap = {}
      if (this.data) {
        this.data.forEach(v => {
          if (!orgMap[v.busOrgId]) {
            orgMap[v.busOrgId] = {
              id: v.busOrgId,
              name: v.busOrgName || '未指定',
              total: v.total || 0
            }
          } else {
            orgMap[v.busOrgId].total += v.total
          }
        })
      }
      let data = Object.keys(orgMap).map(key => {
        let item = orgMap[key]
        item.data = []
        item.data.push(item.total || 0)
        return item
      })
      return data
    }
  }
}
</script>

<style scoped lang="less">
.chart {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
