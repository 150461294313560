<template>
  <Block :theme="theme" title="会议材料" @clickBtn="openFile = true" :actions="[{key: 'more', label: '更多材料'}]">
    <div class="files">
      <file-manage
      :file-data-ids="fileIds || []"
      :funs="{get: true}"
    />
    </div>
    <fm-modal title="会议材料" :mask-closable="false" v-model="openFile" @cancel="openFile = false" width="600px">
      <file-manage
        :file-data-ids="fileIds || []"
        :funs="{get: true}"
      />
    </fm-modal>
  </Block>
</template>

<script>
import Block from '../cmp/block'
import FileManage from '@/components/base/FileManage'

export default {
  components: {
    Block,
    FileManage
  },
  data () {
    return {
      openFile: false
    }
  },
  props: {
    fileIds: {
      type: Array
    },
    theme: {
      type: String
    }
  }
}
</script>

<style scoped lang="less">
.files {
  width: 100%;
  max-height: 272px;
  overflow-y: auto;
}
</style>
