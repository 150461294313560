<template>
  <Block theme="orange" title="审核通过情况">
    <chart-pie :colors="['#FF9D6B', '#FFE369']" class="chart" :dataList="dataList"></chart-pie>
  </Block>
</template>

<script>
import ChartPie from '../cmp/pie'
import Block from '../cmp/block'

export default {
  components: {
    Block,
    ChartPie
  },
  props: {
    data: {
      type: Object
    },
    theme: {
      type: String
    }
  },
  computed: {
    dataList () {
      return [{
        name: '已通过',
        value: this.data.pass || 0
      },
      {
        name: '未通过',
        value: this.data.notPass || 0
      }]
    }
  }
}
</script>

<style scoped lang="less">
.chart {
  width: 100%;
  height: 100%;
}
</style>
